<template>
<div>
    <b-modal id="modal-add" ref="my-modal-add" title="Form Add Product Promo" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" button-size="sm">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-6">
                    <label class="text text-info">Select Product</label>
                    <v-select label="name" :filterable="false" :options="products" @search="onSearchProduct" @input="onSelectProduct($event)">
                        <template slot="no-options"> type to search product... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                [{{ option.part_number}}] {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-3 col-lg-3">
                    <label class="text text-info">Promo Start</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.start_date">
                    </b-form-datepicker>
                </div>
                <div class="form-group col-md-3 col-lg-3">
                    <label class="text text-info">Promo End</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.end_date" :min="formInput.start_date">
                    </b-form-datepicker>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Price</label>
                    <currency-input readonly type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="formInput.price" :reduce="product => product.price" />
                    <label class="text-danger error">{{ validation.firstError('formInput.price') }}</label>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Discount (%)</label>
                    <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="formInput.discount" />
                    <label class="text-danger error">{{ validation.firstError('formInput.discount') }}</label>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Description (Optional)</label>
                    <input type="text" class="form-control" required v-model="formInput.description" />
                </div>
                <div class="form-group col-md-12 col-lg-12">
                    <b-form-checkbox @change="getVoucherCode" id="checkbox-1" v-model="generate_voucher" name="checkbox-1" :value="true" :unchecked-value="false">
                        Generate Voucher?
                    </b-form-checkbox>
                </div>
                <template v-if="generate_voucher">
                    <div class="form-group col-md-6 col-lg-6">
                        <label class="text text-info">Referring Customer</label>
                        <v-select label="first_name" :filterable="false" :options="customers" @search="onSearchCustomer" @input="onSelectCustomer($event)">
                            <template slot="no-options">type to search customer... </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.first_name}} {{ option.last_name }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.first_name }} {{option.last_name}}
                                </div>
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group col-md-6 col-lg-6">
                        <label class="text text-info">Your Voucher Code</label>
                        <input readonly type="text" class="form-control" required v-model="formInput.voucher_code" />
                    </div>
                    <!-- <div class="col-md-6 col-lg-6">
                        <b-button variant="outline-success" @click="getVoucherCode">Generate Voucher</b-button>
                    </div> -->
                </template>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
    Validator
} from 'simple-vue-validator';

export default {
    name: "AddPromo",
    data() {
        return {
            products: [],
            customers: [],
            generate_voucher: false,
            formInput: {
                id_product: "",
                start_date: "",
                end_date: "",
                price: 0,
                discount: 0,
                description: "",
                voucher_code: ""
            },
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
            },
        };
    },
    validators: {
        'formInput.start_date': function (value) {
            return Validator.value(value).required('Start Date is required');
        },
        'formInput.end_date': function (value) {
            return Validator.value(value).required('End Date is required');
        },
        'formInput.price': function (value) {
            return Validator.value(value).required('Price is required');
        },
        'formInput.discount': function (value) {
            return Validator.value(value).required('Discount is required');
        },
    },
    methods: {
        onSelectProduct(product) {
            this.formInput.id_product = product.id
            this.formInput.price = product.price
        },
        onSelectCustomer(customer) {
            this.formInput.refer_to = customer.id
        },
        async onSearchProduct(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/store/mobile/product", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                        id_brand: [],
                        id_category: []
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.products = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async onSearchCustomer(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                let resp = await axios.post("user-service/management/user/list?is_ecommerce=true", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.customers = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Customer", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Customer ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getVoucherCode() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.get("dental-erp-service/generate/number?type=VC&back_date=0", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.formInput.voucher_code = resp.data.data.number
                    // console.log(resp.data.data.number)
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/store/mobile/product_promo/store",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide("modal-add");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.formInput = {
                        id_product: "",
                        start_date: "",
                        end_date: "",
                        price: 0,
                        discount: 0,
                        description: "",
                        voucher_code: ""
                    };
                    
                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
