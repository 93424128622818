<template>
<div class="container-fluid">
    <MenuApp />
    <div v-if="this.$router.app._route.name != 'Management'" class="row">
        <!-- <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body orders-summary">
                            <div class="widget-timeline-icon">
                                <div class="row align-items-center mx-0">
                                    <div class="col-md-12 px-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-lg-6 col-xl-6 col-md-6">
            <div class="row">
                <div class="col-lg-6 pl-1 pr-1">
                    <div class="widget-stat card">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/HRM.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{listUser.length}}</span>
                                    </h3>
                                    <p class="mb-0">Total User</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-1 pr-1">
                    <div class="widget-stat card">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Invoice.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{pendingTransaction.length}}</span>
                                    </h3>
                                    <p class="mb-0">Pending Transaction</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-1 pr-1">
                    <div v-for="(transaction, index) in lastTransaction" :key="index" class="widget-stat card">
                        <div class="card-body p-4">
                            <h4 class="card-title">Recent Product Ordered</h4>
                            <div v-for="(val, index) in transaction.carts" :key="index">
                                <img v-if="val.product.files != null" :src="val.product.files[0].file_url" width="150" height="150">
                                <p class="mt-1">{{val.product.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-1 pr-1">
                    <div v-for="(val, index) in topSelling" :key="index" class="widget-stat card">
                        <div class="card-body p-4">
                            <h4 class="card-title">Top Selling</h4>
                            <img v-if="val.files != null" :src="val.files[0].file_url" width="150" height="150">
                            <p class="mt-1">{{val.name}}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-6 pl-1 pr-1">
                    <div class="widget-stat card">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Asset-Management.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{totalDelivered}}</span>
                                    </h3>
                                    <p class="mb-0">Total Delivered</p>
                                    <small>Sales Orders</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-1 pr-1">
                    <div class="widget-stat card">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Digital-Document.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{approved}}</span>
                                    </h3>
                                    <p class="mb-0">Total Approved</p>
                                    <small>Sales Orders</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-1 pr-1">
                    <div class="widget-stat card">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Task.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{denied}}</span>
                                    </h3>
                                    <p class="mb-0">Total Denied</p>
                                    <small>Sales Orders</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import MenuApp from '../../components/MenuApp';
import axios from "axios";
// import PieChart from '@/components/PieChart';

export default {
    name: "Dashboard",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Dashboard E-commerce | %s',
    },
    components: {
        MenuApp,
        // PieChart,
    },
    data() {
        return {
            // denied: 0,
            // totalSO: 0,
            // totalDelivered: 0,
            // approved: 0,
            // target: 0,
            // realization: 0,
            // dropping: 0,
            // totalSalesOrder: 0,
            listUser: [],
            lastTransaction: [],
            pendingTransaction: 0,
            topSelling: [],
            paramQuery: {
                page: 1,
                limit: 1,
                search: "",
                column: "",
                ascending: false,
            },
            paramQuery2: {
                page: 1,
                limit: 999,
                search: "",
                column: "",
                ascending: false,
                status_paid: "unpaid",
                is_ecommerce: true
            },
        };
    },
    methods: {
        // async getData() {
        //     try {
        //         await this.$store.dispatch('setLoading', true);
        //         let resp = await axios.get('dental-erp-service/counting/so', {
        //             headers: {
        //                 Authorization: localStorage.getItem("token") || "",
        //             },
        //         });

        //         if (resp.data.error === false) {
        //             this.denied = resp.data.data.total_so_denied;
        //             this.totalSO = resp.data.data.total_so_created;
        //             this.totalDelivered = resp.data.data.total_so_delivered;
        //             this.approved = resp.data.data.total_so_approved;
        //         }
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Error", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Error", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     } finally {
        //         this.$store.dispatch('setLoading', false);
        //     }
        // },

        async getLastTransaction() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post('dental-erp-service/store/mobile/transaction', this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.lastTransaction = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getPendingTransaction() {
            try {
                // console.log(this.$router.app._route.name)
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post('dental-erp-service/store/mobile/transaction', this.paramQuery2, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.pendingTransaction = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getListUser() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post('user-service/management/user/list', this.paramQuery2, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.listUser = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getTopSelling() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post('dental-erp-service/store/mobile/product_popular', this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.topSelling = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        }
    },
    async mounted() {
        await this.$store.dispatch("setApp");
        // await this.getData();
        await this.getLastTransaction();
        await this.getTopSelling();
        await this.getPendingTransaction();
        await this.getListUser();
    },
};
</script>
