<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">

        </div>
        <router-link class="btn btn-outline-info" to="consignment" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Transaction List
        </router-link>
    </div>
    <div class="row">
        <div class="col-xl-9 col-xxl-10 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mx-0">
                                <!-- <div class="media align-items-center col-md-4 col-lg-3 col-xl-2 px-0  mb-md-0">
                                    <img class="mr-3 img-fluid rounded-circle" width="65" src="/assets/images/delivery.png" alt="DexignZone">
                                    <div class="media-body">
                                        <small>Courier Service</small>
                                        <h4 class="my-0 text-black"> {{ transaction.courier.name }}</h4>
                                    </div>
                                </div> -->
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">No.Invoice</small>
                                    <p class="text-detail-css"><strong>{{transaction.no_invoice}}</strong></p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">Courier Service</small>
                                    <p class="text-detail-css">{{transaction.courier.name}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">Transaction Date</small>
                                    <p class="text-detail-css">{{transaction.created_at | moment('YYYY-MM-DD')}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">Due Date</small>
                                    <p class="text-detail-css">{{transaction.invoice_due_date}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">Payment Method</small>
                                    <p class="text-detail-css">{{transaction.payment_method}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">Status Paid</small>
                                    <p>
                                        <!-- <span class="btn btn-xs mt-2" :class="transaction.status_paid == 'Paid' ? 'btn-success' : 'btn-danger' ">{{ transaction.status_paid }}</span> -->
                                        <strong><span :class=" transaction.status_paid == 'Unpaid' ? 'text-danger' : 'text-success' ">{{ transaction.status_paid }} </span></strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title mb-5">Tracking</h4>
                                    <div class="hori-timeline" dir="ltr">
                                        <ul class="list-inline events" style="border-top:none">
                                            <li class="list-inline-item event-list">
                                                <div>
                                                    <div class="event-date bg-soft-primary text-primary">2 Juni</div>
                                                    <h5 class="font-size-16">On Process</h5>
                                                </div>
                                            </li>
                                            <li class="list-inline-item event-list">
                                                <div>
                                                    <div class="event-date bg-soft-primary text-primary">3 Juni</div>
                                                    <h5 class="font-size-16">Package Arrived at Soekarno Hatta International Airport</h5>
                                                </div>
                                            </li>
                                            <li class="list-inline-item event-list">
                                                <div>
                                                    <div class="event-date bg-soft-success text-success">5 Juni</div>
                                                    <h5 class="font-size-16">Courier pick up</h5>
                                                    <p class="text-muted">JNE</p>
                                                    <p class="text-muted">Name : Didot Sugiharto</p>
                                                </div>
                                            </li>
                                            <li class="list-inline-item event-list">
                                                <div>
                                                    <div class="event-date bg-soft-success text-success">7 Juni</div>
                                                    <h5 class="font-size-16">Package Sent</h5>
                                                    <p class="text-muted">Name : Mbarep</p>
                                                    <p class="text-muted">Phone : 081270998533</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Product List</h4>
                        </div>
                        <div class="card-body">
                            <div id="accordion-two" class="accordion accordion-primary">
                                <div class="accordion__item">
                                    <div class="accordion__header" data-toggle="collapse" data-target="#bordered_collapseOne"> <span class="accordion__header--text">Order Detail</span>
                                        <span class="accordion__header--indicator"></span>
                                    </div>
                                    <div id="bordered_collapseOne" class="collapse accordion__body show" data-parent="#accordion-two">
                                        <div class="accordion__body--text">
                                            <div class="card-body">
                                                <div class="row mb-5">
                                                    <div class="mt-4 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                                        <div class="row">
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12"> <strong><u> Destination </u></strong> </div>
                                                            <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">Address: {{transaction.send_to.address}}</div>
                                                            <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">Province: {{transaction.send_to.id_external_city.province}}</div>
                                                            <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">City: {{transaction.send_to.id_external_city.city_name}}</div>
                                                            <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">Zip Code: {{transaction.send_to.zip_code}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-4 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12"> <strong><u> Price </u></strong> </div>
                                                        <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">Sub Total: </div>
                                                        <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">Shipping Charge: </div>
                                                        <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">Tax: </div>
                                                        <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">Total: </div>
                                                    </div>
                                                    <div class="mt-4 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="min-height: 23.1px;"></div>
                                                        <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">{{transaction.sub_total.toLocaleString('id-ID')}}</div>
                                                        <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">{{transaction.shipping_charge.toLocaleString('id-ID')}}</div>
                                                        <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">{{transaction.tax.toLocaleString('id-ID')}}</div>
                                                        <div class="mt-3 col-xl-12 col-lg-12 col-md-12 col-sm-12"> <strong> {{transaction.total.toLocaleString('id-ID')}} </strong></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion__item">
                                    <div class="accordion__header collapsed" data-toggle="collapse" data-target="#bordered_collapseTwo"> <span class="accordion__header--text">Ordered Product</span>
                                        <span class="accordion__header--indicator"></span>
                                    </div>
                                    <div id="bordered_collapseTwo" class="collapse accordion__body" data-parent="#accordion-two">
                                        <div class="accordion__body--text">
                                            <div class="table-responsive">
                                                <table class="table table-responsive-m">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">Part Number</th>
                                                            <th class="text-left">Product Name</th>
                                                            <th class="text-left">Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(value, index) in transaction.carts" :key="index">
                                                            <td class="text-left">{{ value.product.part_number }}</td>
                                                            <td class="text-left">{{ value.product.name }}</td>
                                                            <td class="text-left">{{ value.qty }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-xxl-2 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card h-auto">
                        <div class="card bg-secondary sticky mb-0">
                            <div class="card-header border-0 pb-0">
                                <h5 class="card-title text-white mt-2">Transaction by : </h5>
                            </div>
                            <div class="card-body pt-3">
                                <!-- <p class="fs-14 op7 text-white">{{ shelter.note }} </p> -->
                            </div>
                            <div class="card-footer border-0 py-4 bg-primary">
                                <div class="media align-items-center">
                                    <img class="mr-3 img-fluid rounded-circle" width="50" :src="transaction.create_by_data.profile" alt="DexignZone">
                                    <div class="media-body">
                                        <h5 class="my-0 text-white"> {{ transaction.create_by_data.first_name }}{{ transaction.create_by_data.last_name }} </h5>
                                    </div>
                                </div>
                                <div class="media align-items-center">
                                    <!-- <img class="mr-3 img-fluid rounded-circle" width="50" :src="transaction.create_by_data.profile" alt="DexignZone"> -->
                                    <div class="media-body">
                                        <!-- <h5 class="mt-3 my-0 text-white">{{ transaction.create_by_data.title }} </h5> -->
                                        <h5 class="mt-3 my-0 text-white">{{ transaction.create_by_data.email }} </h5>
                                        <h5 class="mt-3 my-0 text-white">Phone: {{ transaction.create_by_data.phone_number }} </h5>
                                    </div>
                                </div>
                            </div>
                            <div v-if="transaction.note != null" class="card-header border-0 pb-0">
                                <h5 class="card-title text-white mt-2">Note Order</h5>
                            </div>
                            <div v-if="transaction.note != null" class="card-body pt-3">
                                <p class="fs-14 op7 text-white">{{ transaction.note }} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card">
                        <div class="card-header border-0 pb-0">
                            <h4 class="card-title">Info</h4>
                        </div>
                        <div class="card-body">
                            <div class="widget-timeline-icon">
                                <ul class="timeline">
                                    <li v-if="transaction.created_at !== undefined">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">Created Transaction</h4>
                                            <p class="fs-15 mb-0 ">{{ transaction.created_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                    <li v-if="timeline.info_so !== undefined">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">SO : {{timeline.info_so.no_so}}</h4>
                                            <p class="fs-15 mb-0 ">{{ timeline.info_so.created_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                    <li v-for="(v, i) in timeline.info_payment" :key="'pay' + i">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">INV : {{v.no_invoice}} <span class="badge badge-sm" :class="v.status == 'Paid' ? 'badge-success' : 'badge-info'">{{v.status}}</span></h4>
                                            <p class="fs-15 mb-0 ">{{ v.invoice_date | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
// const moment = require('moment');

export default {
    name: "DetailTransaction",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Transaction | %s',
    },
    data() {
        return {
            transaction: [],
            timeline: {},
            id_sales_order: "",
        };
    },
    async created() {
        await this.onLoad()
        // await this.getTimeline()
    },
    methods: {
        async getTimeline() {
            try {
                if (!this.$route.query.id) {
                    return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                }

                const resp = await axios.post(
                    "dental-erp-service/sales_order/sales/timeline", {
                        id: this.id_sales_order,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.timeline = resp.data.data;
                }

                return this.$router.push('detail-transaction');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Sales Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Order ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('detail-transaction');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/store/mobile/transaction/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.transaction = resp.data.data;
                    this.id_sales_order = resp.data.data.id_sales_order
                }

                await this.getTimeline()

                // return this.$router.push('management/dashboard');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Transaction", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Transaction ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.hori-timeline .events {
    border-top: 3px solid #e9ecef;
}

.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}

.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 0;
}

.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    width: 75px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}

@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }

    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}

.bg-soft-primary {
    background-color: rgba(64, 144, 203, .3) !important;
}

.bg-soft-success {
    background-color: rgba(71, 189, 154, .3) !important;
}

.bg-soft-danger {
    background-color: rgba(231, 76, 94, .3) !important;
}

.bg-soft-warning {
    background-color: rgba(249, 213, 112, .3) !important;
}
</style>
