<template>
<transition name="fade" mode="out-in" appear>
    <component :is="urlQuery"></component>
</transition>
</template>

<script>
import Dashboard from '../views/AdminModule/DashboardEcommerce'
import EcommerceBanner from '../views/AdminModule/Ecommerce/Banner'
import EcommercePromo from '../views/AdminModule/Ecommerce/Promo'
import EcommerceTransaction from '../views/AdminModule/Ecommerce/Transaction'
import EcommerceUser from '../views/AdminModule/Ecommerce/User'
import DetailTransaction from '../views/AdminModule/Ecommerce/DetailTransaction'
import EcommerceVoucher from '../views/AdminModule/Ecommerce/Voucher'

export default {
    name: 'Admin',
    data() {
        return {
            menuAdmin: [{
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Ecommerce',
                        module_slug_name: 'Ecommerce',
                        name: '/ecommerce/dashboard',
                        slug_name: '/ecommerce/dashboard'
                    },
                    menu: {
                        name: 'Dashboard',
                        slug_name: 'dashboard',
                        icon: 'flaticon-381-home',
                        image_icon: 'https://storages.solusidental.id/media/6328821611739794-dashboard-icon.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Ecommerce',
                        module_slug_name: 'Ecommerce',
                        name: '/ecommerce/ecommerce-banner',
                        slug_name: '/ecommerce/ecommerce-banner'
                    },
                    menu: {
                        name: 'Banner',
                        slug_name: 'ecommerce',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/Timessheet.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Ecommerce',
                        module_slug_name: 'Ecommerce',
                        name: '/ecommerce/ecommerce-promo',
                        slug_name: '/ecommerce/ecommerce-promo'
                    },
                    menu: {
                        name: 'Product Promo',
                        slug_name: 'ecommerce',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/Procurement.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Ecommerce',
                        module_slug_name: 'Ecommerce',
                        name: '/ecommerce/ecommerce-transaction',
                        slug_name: '/ecommerce/ecommerce-transaction'
                    },
                    menu: {
                        name: 'Transaction',
                        slug_name: 'ecommerce',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/Invoice.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Ecommerce',
                        module_slug_name: 'Ecommerce',
                        name: '/ecommerce/ecommerce-voucher',
                        slug_name: '/ecommerce/ecommerce-voucher'
                    },
                    menu: {
                        name: 'Voucher',
                        slug_name: 'ecommerce',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/Expense.png'
                    }
                },
                {
                    action: ["view", "create", "delete", "update"],
                    component: {
                        module_name: 'Ecommerce',
                        module_slug_name: 'Ecommerce',
                        name: '/ecommerce/ecommerce-user',
                        slug_name: '/ecommerce/ecommerce-user'
                    },
                    menu: {
                        name: 'User',
                        slug_name: 'ecommerce',
                        icon: 'flaticon-381-home',
                        image_icon: '/assets/icon/HRM.png'
                    }
                },
            ]
        }
    },
    components: {
        Dashboard,
        EcommerceBanner,
        EcommercePromo,
        EcommerceTransaction,
        EcommerceUser,
        DetailTransaction,
        EcommerceVoucher
    },
    async created() {
        await this.$store.dispatch('setMenu', this.menuAdmin);
    },
    computed: {
        urlQuery() {
            return this.$route.params.component || 'dashboard';
        }
    }
}
</script>
