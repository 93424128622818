<template>
<div>
    <b-modal id="modal-add" ref="my-modal-add" title="Form Add Banner" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info">Sequence</label>
                    <input type="number" class="form-control" placeholder="Example : Sequence 1, 2, 3" required v-model="formInput.sequence" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info mb-3">Images</label>
                    <b-form-file accept="image/*" v-on:change="onFileChange"></b-form-file>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import Compressor from 'compressorjs';

export default {
    name: "AddBanner",
    data() {
        return {
            formInput: {
                name: "",
                sequence: "",
                file_name: "",
                file_content: "",
                file_size: "1440x766"
            },
        };
    },
    methods: {
        async onFileChange(e) {
            const files = e.target.files[0]
            if (!files) return;

            const reader = new FileReader();
            reader.readAsDataURL(files);
            reader.fileName = files.name

            reader.onload = () => {
                this.formInput.file_name = reader.fileName
                this.formInput.file_content = reader.result
            };
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/store/mobile/banner/store",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide("modal-add");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.formInput.name = '';
                    this.$parent.getData();
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}
</style>
