<template>
<div>
    <!-- <div class="row page-titles mx-0">
        <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
                <h4>MY APPLICATIONS</h4>
            </div>
        </div>
    </div> -->
    <!-- row -->
    <div class="row">
        <div v-if="menu.length == 0" class="col-sm-12 text text-info text-center">
            <h1>No Application List</h1>
            <br />
        </div>
        <div v-else class="col-xl-2 col-lg-6 col-xxl-2 col-sm-6" v-for="(app, index) in filterMenu" :key="index">
            <div class="card card-application" v-on:click="chooseApplication(app)">
                <div class="card-body text-center ai-icon text-primary">
                    <img :src="app.menu.image_icon" width="85" height="85" style="object-fit: cover">
                    <h3 class="my-2">{{ app.menu.name }}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <h2>E-commerce Menu</h2>
        </div> -->
        <div v-if="menu.length == 0" class="col-sm-12 text text-info text-center">
            <h1>No Application List</h1>
            <br />
        </div>
        <div v-else class="col-xl-2 col-lg-6 col-xxl-2 col-sm-6 mt-4" v-for="(app, index) in filterEcommerce" :key="index">
            <div class="card card-application" v-on:click="chooseApplication(app)">
                <div class="card-body text-center ai-icon text-primary">
                    <img :src="app.menu.image_icon" width="85" height="85" style="object-fit: cover">
                    <h3 class="my-2">{{ app.menu.name }}</h3>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import axios from "axios";
import {
    mapGetters
} from 'vuex'

export default {
    name: "MenuApp",
    data() {
        return {
            listApplication: [],
        };
    },
    computed: {
        ...mapGetters(['menu']),
        filterMenu() {
            return this.menu.filter(i => i.menu.name != 'Dashboard' && i.menu.slug_name != 'ecommerce')
        },
        filterEcommerce() {
            return this.menu.filter(i => i.menu.slug_name == 'ecommerce')
        },
    },
    methods: {
        // async renderApp() {
        //     try {
        //         const resp = await axios.get("user-service/menu", {
        //             headers: {
        //                 Authorization: localStorage.getItem("token") || "",
        //             },
        //         });
        //         if (resp.data.error === false) {
        //             this.listApplication = resp.data.data;
        //             await this.$store.dispatch('setMenu', resp.data.data);
        //             return;
        //         }
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Get Application List", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Get Application List ", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     }
        // },
        async chooseApplication(e) {
            this.$store.dispatch('setAppRole', e.action)
            this.$router.push(e.component.name);
        },
    },
};
</script>

<style scoped>
.card-application:hover {
    box-shadow: 8px 8px 5px rgb(99, 99, 112);
    transform: scale(1.1);
    cursor: pointer;
}
</style>
