var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"my-modal-edit",attrs:{"id":"modal-edit","title":"Form Edit Product Promo","size":"xl","centered":"","no-close-on-esc":"","no-close-on-backdrop":""},on:{"ok":_vm.handleOk,"hidden":_vm.$parent.handleCancel}},[_c('form',{ref:"formAdd",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3 col-lg-3"},[_c('label',{staticClass:"text text-info"},[_vm._v("Start")]),_c('b-form-datepicker',{attrs:{"no-flip":"","date-format-options":{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        },"locale":"id"},model:{value:(_vm.formInput.start_date),callback:function ($$v) {_vm.$set(_vm.formInput, "start_date", $$v)},expression:"formInput.start_date"}})],1),_c('div',{staticClass:"form-group col-md-3 col-lg-3"},[_c('label',{staticClass:"text text-info"},[_vm._v("End")]),_c('b-form-datepicker',{attrs:{"no-flip":"","date-format-options":{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        },"locale":"id","min":_vm.formInput.start_date},model:{value:(_vm.formInput.end_date),callback:function ($$v) {_vm.$set(_vm.formInput, "end_date", $$v)},expression:"formInput.end_date"}})],1),_c('div',{staticClass:"form-group col-md-6 col-lg-4"},[_c('label',{staticClass:"text text-info"},[_vm._v("Price")]),_c('currency-input',{staticClass:"form-control",attrs:{"type":"text","valueAsInteger":true,"precision":0,"currency":null,"locale":"id","allowNegative":false,"distractionFree":false,"required":""},model:{value:(_vm.formInput.price),callback:function ($$v) {_vm.$set(_vm.formInput, "price", $$v)},expression:"formInput.price"}})],1),_c('div',{staticClass:"form-group col-md-6 col-lg-4"},[_c('label',{staticClass:"text text-info"},[_vm._v("Discount (%)")]),_c('currency-input',{staticClass:"form-control",attrs:{"type":"text","valueAsInteger":true,"precision":0,"currency":null,"locale":"id","allowNegative":false,"distractionFree":false,"required":""},model:{value:(_vm.formInput.discount),callback:function ($$v) {_vm.$set(_vm.formInput, "discount", $$v)},expression:"formInput.discount"}})],1),_c('div',{staticClass:"form-group col-md-6 col-lg-4"},[_c('label',{staticClass:"text text-info"},[_vm._v("Description (Optional)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formInput.description),expression:"formInput.description"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter the name","required":""},domProps:{"value":(_vm.formInput.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formInput, "description", $event.target.value)}}})])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }